import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage/HomePage'
import AboutPage from './pages/AboutPage/AboutPage'
import PortfolioPage from './pages/PortfolioPage/PortfolioPage'
import ProjectPage from './pages/ProjectPage/ProjectPage'
import ContactPage from './pages/ContactPage/ContactPage'
import ErrorPage from './pages/ErrorPage/ErrorPage'
import TestimonialsPage from './pages/TestimonialsPage/TestimonialsPage'
import ComingSoonPage from './pages/ComingSoonPage/ComingSoonPage'

function App() {
  return (
    <div className="App">
      <ComingSoonPage />
      {/* <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/about">
          <AboutPage />
        </Route>
        <Route exact path="/portfolio">
          <PortfolioPage />
        </Route>
        <Route exact path="/portfolio/:spaceTitle">
          <ProjectPage />
        </Route>
        <Route exact path="/testimonials">
          <TestimonialsPage />
        </Route>
        <Route exact path="/contact">
          <ContactPage />
        </Route>
        <Route exact path="/:anything">
          <ErrorPage />
        </Route>
      </Switch> */}
    </div>
  );
}

export default App;
