import React from 'react';
import anchorRope from '../../images/SS_anchor_rope.png'
import './ComingSoonPage.css'

export default function ComingSoonPage(){
    return(
        <>
            <div className="coming-soon">
                <img src={anchorRope} className="logo-coming-soon"/>
                <div className="name">
                    <h1>SHIP SHAPE</h1>
                    <h3>COASTAL INTERIORS</h3>
                </div>
                
                <br />
                <h4>(coming soon)</h4>
            </div>
        </>
    )
}